/* eslint-disable no-self-assign */
/* eslint-disable handle-callback-err */
/* eslint-disable quote-props */
/* eslint-disable no-undef */
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Vuex from 'vuex'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.use(VueAxios, axios)
Vue.use(Vuex)
const state = {
  refreshtoken: '',
  accesstoken: '',
  profile: {},
  authorize: false
}

const mutations = {
  auth_portal_check (state, data) {
    state.refreshtoken = data
    state.accesstoken = ''
    state.authorize = false
    state.profile = state.profile
  },
  auth_portal_success (state, data) {
    state.refreshtoken = state.refreshtoken
    state.accesstoken = data.accesstoken
    state.authorize = true
    state.profile = state.profile
  },
  auth_portal_expired (state) {
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
    state.profile = {}
  },
  account_portal_check (state, data) {
    state.refreshtoken = state.refreshtoken
    state.accesstoken = state.accesstoken
    state.authorize = state.authorize
    state.profile = state.profile
  },
  account_portal_success (state, data) {
    var currentuser = data.business_owner.find(d => (d.username).toLowerCase() === (localStorage.getItem('DHL_Username')).toLowerCase())
    data.currentuser = currentuser
    state.refreshtoken = state.refreshtoken
    state.accesstoken = state.accesstoken
    state.authorize = true
    state.profile = data
  },
  account_portal_notfound (state) {
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
    state.profile = {}
  },
  auth_signout (state) {
    state.refreshtoken = ''
    state.accesstoken = ''
    state.authorize = false
  }
}

const getters = {
  getter_dhl_accesstoken: state => state.accesstoken,
  getter_dhl_refreshtoken: state => state.refreshtoken,
  getter_dhl_profile: state => state.profile,
  getter_dhl_authorize: state => state.authorize
}

const actions = {

  auth_portal_success ({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('auth_portal_success', data)
    })
  },
  auth_portal_expired ({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('auth_portal_expired', data)
    })
  },
  account_portal_success ({ commit }, data) {
    commit('account_portal_success', data)
  },
  account_portal_notfound ({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('account_portal_notfound', data)
    })
  },

  auth_portal_check ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('DHL_Access') != null) {
        commit('auth_portal_check', JSON.parse(localStorage.getItem('DHL_Access')).data.refreshtoken)
        axios.post(process.env.VUE_APP_REGETAXSP_API + '/getaccusr_getbusiness', { token: JSON.parse(localStorage.getItem('DHL_Access')).data.refreshtoken }, { withCredentials: false })
          .then((response) => {
            if (response.data.status === 'success') {
              resolve(response.data)
              commit('auth_portal_success', response.data)
              dispatch('account_portal_check', response.data)
            } else {
              commit('auth_portal_expired')
              resolve(response.data)
              localStorage.removeItem('DHL_Access')
              localStorage.removeItem('DHL_Username')
            }
          })
      } else {
        commit('auth_portal_check', '')
        commit('auth_portal_expired')
        resolve({ result: 'fail' })
        localStorage.removeItem('DHL_Access')
        localStorage.removeItem('DHL_Username')
      }
    })
  },

  account_portal_check ({ commit, dispatch }, data) {
    commit('account_portal_check')
    axios.post(process.env.VUE_APP_REGETAXSP_API + '/usr_getbusiness', { refreshToken: JSON.parse(localStorage.getItem('DHL_Access')).data.refreshtoken, accessToken: data.accesstoken }, { withCredentials: false })
      .then((response) => {
        if (response.data.result === 'Success') {
          commit('account_portal_success', response.data.data[0])
        } else {
          dispatch('account_portal_notfound', response.data)
          commit('auth_portal_expired')
        }
      }).catch((error) => {
        console.log(error)
        commit('auth_portal_expired')
      })
  }

}
const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions
})
global.store = store
export default store
